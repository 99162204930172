[class^="icon-"]:before, [class*=" icon-"]:before {
  display: inline-block;
  font-family: 'Santander Icons', sans-serif;
  font-size: 120%;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1em;
  speak: never;
  text-align: center;
  text-decoration: inherit;
  width: 1em;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account-user:before { content: '\e800'; } /* '' */
.icon-ai-star:before { content: '\e801'; } /* '' */
.icon-all-day-clock:before { content: '\e802'; } /* '' */
.icon-apple:before { content: '\e803'; } /* '' */
.icon-armchair:before { content: '\e804'; } /* '' */
.icon-arrow-down:before { content: '\e805'; } /* '' */
.icon-arrow-left:before { content: '\e806'; } /* '' */
.icon-arrow-right:before { content: '\e807'; } /* '' */
.icon-arrow-up:before { content: '\e808'; } /* '' */
.icon-bill:before { content: '\e809'; } /* '' */
.icon-box:before { content: '\e80a'; } /* '' */
.icon-cancel-fill:before { content: '\e80b'; } /* '' */
.icon-card-getnet:before { content: '\e80c'; } /* '' */
.icon-call-center:before { content: '\e80d'; } /* '' */
.icon-care-hand:before { content: '\e80e'; } /* '' */
.icon-cargo-cart:before { content: '\e80f'; } /* '' */
.icon-checked-user:before { content: '\e810'; } /* '' */
.icon-checkmark:before { content: '\e811'; } /* '' */
.icon-checkout-acccount:before { content: '\e812'; } /* '' */
.icon-chevron-left:before { content: '\e813'; } /* '' */
.icon-circle-checkmark:before { content: '\e814'; } /* '' */
.icon-countryside:before { content: '\e815'; } /* '' */
.icon-documents:before { content: '\e816'; } /* '' */
.icon-close:before { content: '\e817'; } /* '' */
.icon-facebook:before { content: '\e818'; } /* '' */
.icon-filter:before { content: '\e819'; } /* '' */
.icon-email:before { content: '\e81a'; } /* '' */
.icon-hamburger-menu:before { content: '\e81b'; } /* '' */
.icon-google-color:before { content: '\e81c'; } /* '' */
.icon-heart:before { content: '\e81d'; } /* '' */
.icon-clock:before { content: '\e81e'; } /* '' */
.icon-money-pouch:before { content: '\e81f'; } /* '' */
.icon-payment-dollar:before { content: '\e820'; } /* '' */
.icon-hand-financing:before { content: '\e821'; } /* '' */
.icon-figures:before { content: '\e822'; } /* '' */
.icon-padlock-check:before { content: '\e823'; } /* '' */
.icon-pig-plus-sign:before { content: '\e824'; } /* '' */
.icon-hand-dollar-sign-floating:before { content: '\e825'; } /* '' */
.icon-hand-plant:before { content: '\e826'; } /* '' */
.icon-piggy-bank:before { content: '\e827'; } /* '' */
.icon-protected-home:before { content: '\e828'; } /* '' */
.icon-radar:before { content: '\e829'; } /* '' */
.icon-dimensions:before { content: '\e82a'; } /* '' */
.icon-price-award:before { content: '\e82b'; } /* '' */
.icon-pet:before { content: '\e82c'; } /* '' */
.icon-relax-face:before { content: '\e82d'; } /* '' */
.icon-padlock-on-shield:before { content: '\e82e'; } /* '' */
.icon-security-profile:before { content: '\e82f'; } /* '' */
.icon-search:before { content: '\e830'; } /* '' */
.icon-mobile-payment:before { content: '\e831'; } /* '' */
.icon-remodeling:before { content: '\e832'; } /* '' */
.icon-padlock-profile:before { content: '\e833'; } /* '' */
.icon-return:before { content: '\e834'; } /* '' */
.icon-shopping-bag:before { content: '\e835'; } /* '' */
.icon-remodeling-hat:before { content: '\e836'; } /* '' */
.icon-pin-place:before { content: '\e837'; } /* '' */
.icon-house:before { content: '\e838'; } /* '' */
.icon-steps:before { content: '\e839'; } /* '' */
.icon-heart-fill:before { content: '\e83a'; } /* '' */
.icon-thumbs-up:before { content: '\e83b'; } /* '' */
.icon-truck:before { content: '\e83c'; } /* '' */
.icon-trophy:before { content: '\e83d'; } /* '' */
.icon-supermarket-cart:before { content: '\e83e'; } /* '' */
.icon-star:before { content: '\e83f'; } /* '' */
.icon-instagram:before { content: '\e840'; } /* '' */
.icon-visa:before { content: '\e841'; } /* '' */
.icon-jewel:before { content: '\e842'; } /* '' */
.icon-location-start:before { content: '\e843'; } /* '' */
.icon-warehouses:before { content: '\e844'; } /* '' */
.icon-location-end:before { content: '\e845'; } /* '' */
.icon-house-dollar-sign-inside:before { content: '\e846'; } /* '' */
.icon-monitor:before { content: '\e847'; } /* '' */
.icon-location:before { content: '\e848'; } /* '' */
.icon-king:before { content: '\e849'; } /* '' */
.icon-help-circle:before { content: '\e84a'; } /* '' */
.icon-warehouse:before { content: '\e84b'; } /* '' */
.icon-google:before { content: '\e84c'; } /* '' */
.icon-whatsapp:before { content: '\e84d'; } /* '' */
.icon-shipping:before { content: '\e84e'; } /* '' */
.icon-star-fill:before { content: '\e84f'; } /* '' */
.icon-home-assistance:before { content: '\e850'; } /* '' */
.icon-phone-support:before { content: '\e851'; } /* '' */
.icon-santander-isotype:before { content: '\e852'; } /* '' */
.icon-information-circle:before { content: '\e853'; } /* '' */
.icon-stacked-coins:before { content: '\e854'; } /* '' */
.icon-phone-call:before { content: '\e855'; } /* '' */
.icon-investment-fund:before { content: '\e856'; } /* '' */
.icon-unicorn:before { content: '\e857'; } /* '' */
.icon-team-management:before { content: '\e858'; } /* '' */
.icon-tools:before { content: '\e859'; } /* '' */
.icon-checkmark-in-a-circle:before { content: '\e85a'; } /* '' */