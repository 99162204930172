@import 'primeng/resources/themes/lara-light-blue/theme.css';
@import 'primeng/resources/primeng.css';
@import 'primeicons/primeicons.css';
@import 'fonts.scss';
@import 'icons.scss';

:root {
  /***** Light palette ******/
  // Primary
  --light-primary-01: #EC0000;
  --light-primary-02: #CC0000;
  --light-primary-03: #990000;

  // Secondary
  --light-secondary-01: #FFCC33;
  --light-secondary-02: #63B468;
  --light-secondary-03: #1BB3BC;
  --light-secondary-04: #9E3667;
  --light-secondary-05: #F07A7A;

  // Accesible
  --light-accesible-02: #008437;

  // Black & White
  --light-neutral-01: #FFFFFF;
  --light-neutral-02: #191919;

  // UI
  --light-ui-01: #191919;
  --light-ui-02: #595959;
  --light-ui-03: #999999;
  --light-ui-04: #D9D9D9;
  --light-ui-05: #F2F2F2;
  --light-ui-06: #FFFFFF;

  // Text
  --light-text-01: #191919;
  --light-text-02: #4C4C4C;
  --light-text-03: #767676;
  --light-text-04: #999999;
  --light-text-05: #FFFFFF;
  --light-text-06: #EC0000;

  // Background
  --light-background-01: #E5E5E5;
  --light-background-02: #F2F2F2;
  --light-background-03: #FFFFFF;
  --light-background-04: #E2F0F4;
  --light-background-05: #EAF3F6;
  --light-background-06: #1F4FFF;
  --light-background-07: #EBF4F7;

  // Dialog
  --light-dialog-01: #EDB101;
  --light-dialog-02: #1F8926;
  --light-dialog-03: #0032E6;
  --light-dialog-04: #CC0000;
  --light-dialog-01b: #FFF5D6;
  --light-dialog-02b: #EEFBEF;
  --light-dialog-03b: #CCD6FA;
  --light-dialog-03a: #002CCB;
  --light-dialog-04b: #FFE3E3;

  // Shadow
  --light-shadow-01: 0 2px 4px rgba(153, 153, 153, 0.5);
  --light-shadow-02: 0 4px 6px rgba(153, 153, 153, 0.3);
  --light-shadow-03: 0 6px 14px rgba(153, 153, 153, 0.3);
  --light-shadow-04: 0 10px 16px rgba(153, 153, 153, 0.25);
  --light-shadow-05: 0 20px 20px -5px rgba(153, 153, 153, 0.2);
  --light-shadow-06: 4px 5px 0 0 #9BC3D3;
  --light-shadow-07: 0 6px 14px #999999;
  --light-shadow-08: 0 6px 14px -10px rgba(153, 153, 153, 0.3);

  /***** Dark palette ******/
  // UI
  --dark-ui-01: #FFFFFF;

  // Text
  --dark-text-01: #FFFFFF;
  --dark-text-02: #BFBFBF;

  // Background
  --dark-background-01: #191919;

  /***** Fonts ******/
  --font-santander-headline: 'Santander Headline', sans-serif;
  --font-santander-micro-text: 'Santander Micro Text', sans-serif;
  --font-santander-text: 'Santander Text', sans-serif;
  --font-santander-text-light: 'Santander Text Light', sans-serif;
}

* {
  font-family: var(--font-santander-text);
}
html {
  overscroll-behavior: none;
}

html {
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}

p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-santander-headline);
  margin: 0;
}

/****** Animations *****/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
  animation-duration: 650ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@for $i from 1 through 20 {
  $delay: $i * 100ms;
  .animate__delay-#{$i * 100}ms {
    -webkit-animation-delay: $delay;
    animation-delay: $delay;
  }
}

/****** Carousel ******/
.carousel {
  .p-carousel-prev {
    display: none;
  }
  .p-carousel-next {
    display: none;
  }
}
.exclusive-benefits-section {
  display: flex;
  justify-content: center;
  .exclusive-benefits-container {
    background-color: var(--light-neutral-02);
    // TODO: normalize when breakpoints
    max-width: 1366px;
    min-height: 885px;
    padding: 90px 0 0 48px;
    width: 100%;
    .title-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      .title {
        color: var(--light-text-05);
        font-size: 40px;
        font-weight: bold;
        line-height: 42px;
        margin-bottom: 6px;
        text-align: center;
      }
      .subtitle {
        color: var(--light-ui-04);
        font-size: 19px;
        font-weight: normal;
        line-height: 24px;
        margin-bottom: 28px;
      }
      .underline {
        border: none;
        border-top: 4px solid var(--light-primary-01);
        padding-bottom: 64px;
        width: 140px;
      }
    }
    app-retryable-section {
      .retry-container {
        .message {
          color: var(--light-text-05);
        }
      }
    }
    .carousel-exclusive-benefits {
      // TODO: normalize when breakpoints
      max-width: 1366px;
      .card {
        border-radius: 10px;
        color: var(--light-neutral-01);
        height: 460px;
        overflow: hidden;
        position: relative;
        width: 360px;
        .image {
          background-repeat: no-repeat;
          background-size: 100%;
          height: 100%;
          opacity: 1;
          width: 100%;
          transition: all 0.3s linear;
        }
        .card-content {
          background-color: var(--light-ui-02);
          background-size: 100%;
          bottom: 0;
          color: var(--light-neutral-01);
          height: 120px;
          left: 0;
          padding: 13px 18px;
          position: absolute;
          text-align: left;
          transition: all 0.3s linear;
          width: 100%;
          z-index: 1;
          .title {
            font-size: 22px;
            font-weight: bold;
            line-height: 28px;
            margin-bottom: 18px;
            text-align: left;
          }
          .icon-text {
            display: flex;
            gap: 8px;
            justify-content: flex-start;
            .benefit-text {
              font-size: 14px;
              font-weight: normal;
              line-height: 20px;
            }
            &.icon-none {
              i {
                display:none
              }
            }
          }
        }
        .card-information {
          background-color: var(--light-ui-02);
          bottom: 0;
          color: var(--light-neutral-01);
          height: 120px;
          left: 0;
          padding: 20px;
          position: absolute;
          transform: translateY(100%);
          transition: all 0.3s linear;
          width: 100%;
          z-index: 1;
          .divider {
            background-color: var(--light-text-04);
            border: none;
            height: 1px;
            margin: 10px 0 20px;
          }
          .button-container {
            align-items: flex-start;
            display: flex;
          }
        }
        &:hover {
          .card-content {
            height: 240px;
            left: 0;
          }
          .card-information {
            height: 120px;
            transform: translateY(0%);
          }
        }
      }
      .p-carousel-indicators {
        .p-carousel-indicator {
          .p-link {
            background: var(--light-ui-01);
            border: 2px solid var(--light-ui-02);
            border-radius: 25px;
            height: 16px;
            transition: width 600ms;
            width: 16px;
          }
          &.p-highlight {
            .p-link {
              background: var(--light-ui-02);
              transition: width 600ms;
              width: 98px;
            }
          }
        }
      }
    }
  }
}
// TODO: change to component why-portal component
.why-platform-article {
  align-content: center;
  display: flex;
  justify-content: center;
  width: 100%;
  .title-container {
    margin: 80px 16px 0;
    text-align: center;
    .title {
      font-size: 28px;
      margin-bottom: 20px;
    }
    .underline {
      border: none;
      border-top: 4px solid var(--light-primary-01);
      padding-bottom: 46px;
      width: 140px;
    }
    .benefits-container {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      justify-content: space-around;
      margin-top: 20px;
      .benefit {
        display: flex;
        flex-direction: row;
        gap: 12px;
        height: 100px;
        text-align: center;
        width: 274px;
        i {
          color: var(--light-primary-02);
          font-size: 20px;
        }
        .benefit-content {
          display: flex;
          flex-direction: column;
          .label {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 10px;
            text-align: left;
          }
          .description {
            color: var(--light-text-04);
            font-size: 17px;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }
    .become-a-customer {
      margin-bottom: 96px;
      margin-top: 80px;
      font-family: var(--font-santander-micro-text);
      .customer-benefit {
        font-size: 28px;
        font-weight: bold;
        line-height: 30px;
        .customer-link {
          color: var(--light-text-06);
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
    &.mt-0 {
      margin-top: 0;
    }
  }
}
//FAQ: arrows red
.faq-article {
  .faq-container {
    .accordion-faq, .pi-chevron-down,
    .accordion-faq, .pi-chevron-up {
      color: var(--light-primary-01);
    }
  }
}
// TODO: change to faq-component
.faq-article {
  margin: 40px auto;
  // TODO: normalize breakpoints
  max-width: 1366px;
  padding: 0 48px;
  .title {
    color: var(--light-neutral-02);
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 28px;
  }
  .faq-container {
    .accordion-faq {
      .p-accordion {
        .accordion-tab, .p-element {
          .p-accordion-tab {
            border: 4px solid white;
            margin-bottom: 0;
            padding: 0 20px;
            background: var(--light-ui-05);
            border-radius: 8px;
            .p-accordion-header {
              .p-accordion-header-link {
                background: transparent;
                border: 0;
                border-radius: 0;
                color: var(--light-ui-01);
                font-size: 20px;
                font-weight: bold;
                line-height: 28px;
                padding: 24px 0;
              }
              .p-accordion-header-text {
                font-family: var(--font-santander-headline);
                line-height: 24px;
                padding-right: 16px;
              }
            }
            .p-toggleable-content {
              .p-accordion-content {
                background: var(--light-ui-05);
                border: 0;
                color: var(--light-text-01);
                padding: 0 0 24px;
                .text-container {
                  .text {
                    margin-bottom: 8px;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                    .phone-number {
                      text-decoration: underline;
                    }
                    .link {
                      color: var(--light-primary-01);
                      text-decoration: none;
                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }
              &[aria-hidden=true] {
                display: block;
              }
            }
          }
          &:last-of-type {
            .p-accordion-tab {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .help-content {
    font-size: 28px;
    font-weight: bold;
    margin: 30px 0 100px;
    .link {
      color: var(--light-text-06);
      text-decoration: none;
    }
  }
}
// TODO: Normalize breakpoints
@media (width >= 1366px) {
  .carousel {
    &.display-navigation {
      .p-carousel-prev, .p-carousel-next {
        display: block;
        margin: 0 16px;
      }
    }
  }
}

@media (width < 1366px) {
  .exclusive-benefits-section {
    .exclusive-benefits-container {
      padding: 0;
      max-width: 100%;
      min-height: 590px;
      .title-container {
        padding: 48px 29px 0;
        .title {
          font-size: 28px;
          line-height: 30px;
        }
        .subtitle {
          font-size: 17px;
          font-weight: normal;
          line-height: 22px;
          text-align: center;
          margin-bottom: 24px;
        }
        .underline {
          padding-bottom: 32px;
        }
      }
      .carousel-exclusive-benefits {
        max-width: 100%;
        .p-carousel-container {
          margin-left: 18px;
        }
        .card {
          height: 356px;
          width: 280px;
          .image {
            opacity: 1;
            transition: none;
          }
          .card-content {
            height: 190px;
            transition: none;
            .title {
              font-size: 18px;
              line-height: 22px;
              margin-bottom: 12px;
            }
            .icon-text {
              gap: 8px;
              .benefit-text {
                font-family: var(--font-santander-micro-text);
                font-size: 12px;
                line-height: 16px;
              }
              &.icon-none {
                i {
                  display:none
                }
              }
            }
          }
          .card-information {
            bottom: 0;
            height: 90px;
            padding: 0 13px;
            transform: none;
            transition: none;
            .divider {
              margin: 0 0 16px;
            }
          }
          &:hover {
            .image {
              background-size: 100%;
              opacity: 1;
              transform: scale(1);
            }
            .card-content {
              height: 190px;
            }
            .card-information {
              height: 90px;
            }
          }
        }
        .p-carousel-indicators {
          margin-bottom: 22px;
          .p-carousel-indicator {
            .p-link {
              height: 12px;
              width: 12px;
            }
            &.p-highlight {
              .p-link {
                transition: width 600ms;
                width: 98px;
              }
            }
          }
        }
      }
    }
  }
  .why-platform-article {
      .title-container {
        .title {
          margin-bottom: 24px;
        }
        .become-a-customer {
          max-width: 325px;
          margin: 32px auto 48px auto;
          .customer-benefit {
            font-size: 16px;
            line-height: 28px;
          }
        }
        .underline {
          padding-bottom: 28px;
        }
      }
    }
  .faq-article {
    padding: 0 16px;
    .title {
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 16px;
    }
    .help-content {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
.custom-button-clear {
  align-items: center;
  background: none;
  border: none;
  border-radius: 22px;
  display: flex;
  font-size: 19px;
  line-height: 24px;
  height: 44px;
  padding: 0 14px;
  &:hover {
    background: var(--light-ui-05);
    cursor: pointer;
    transition: 150ms;
  }
  .p-overlay-badge {
    .p-badge {
      background: var(--light-primary-01);
      font-size: 10px;
      font-style: normal;
      height: 16px;
      line-height: 16px;
      min-width: 16px;
      width: 16px;
    }
  }
}

/****** Inputs ******/
.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &:last-of-type {
    margin-bottom: 0;
  }
  label {
    color: var(--light-text-01);
    font-size: 14px;
    font-family: var(--font-santander-micro-text);
    line-height: 20px;
    margin-bottom: 8px;
  }
  input {
    color: var(--light-text-01);
    width: 100%;
    &.active {
      border-color: var(--light-ui-01);
      transition: 150ms;
    }
    &:focus {
      border-color: var(--light-ui-02);
      outline: none;
      transition: 150ms;
    }
    &:hover:not(:disabled) {
      border-color: var(--light-ui-03);
      transition: 150ms;
    }
    ::placeholder {
      color: var(--light-text-03);
    }
  }
  .p-inputtext {
    &:enabled {
      &:focus {
        box-shadow: none;
      }
    }
  }
  textarea {
    &.active {
      border-color: var(--light-ui-01);
      transition: 150ms;
    }
    &:focus {
      border-color: var(--light-ui-02);
      outline: none;
      transition: 150ms;
    }
    &:hover:not(:disabled) {
      border-color: var(--light-ui-03);
      transition: 150ms;
    }
    ::placeholder {
      color: var(--light-text-03);
    }
  }
  p-inputNumber {
    .p-inputnumber {
      width: 100%;
      input {
        color: var(--light-text-01);
        &.p-filled {
          border-color: var(--light-ui-01);
          transition: 150ms;
        }
        &:focus {
          border-color: var(--light-ui-02);
          outline: none;
          transition: 150ms;
        }
        &:hover:not(:disabled) {
          border-color: var(--light-ui-03);
          transition: 150ms;
        }
        &::placeholder {
          color: var(--light-text-03);
        }
      }
    }
  }
  p-inputmask {
    &.active {
      input {
        border-color: var(--light-ui-01);
        transition: 150ms;
      }
    }
    &:focus {
      input {
        border-color: var(--light-ui-02);
        outline: none;
        transition: 150ms;
      }
    }
    &:hover {
      input {
        &:hover:not(:disabled) {
          border-color: var(--light-ui-03);
          transition: 150ms;
        }
      }
    }
    input {
      color: var(--light-text-01);
      width: 100%;
      &::placeholder {
        color: var(--light-text-04);
      }
    }
  }
  p-calendar {
    .p-calendar {
      width: 100%;
      input {
        &::placeholder {
          color: var(--light-text-04);
        }
      }
    }
    &.active {
      span {
        input {
          border-color: var(--light-ui-01);
          transition: 150ms;
        }
      }
    }
    &:focus {
      span {
        input {
          border-color: var(--light-ui-02);
          outline: none;
          transition: 150ms;
        }
      }
    }
    &:hover {
      span {
        input {
          border-color: var(--light-ui-03);
          transition: 150ms;
        }
      }
    }
  }
  p-dropdown {
    color: var(--light-text-01);
    &.active {
      .p-dropdown {
        transition: 150ms;
      }
    }
    &:focus {
      .p-dropdown {
        border-color: var(--light-ui-02);
        outline: none;
        transition: 150ms;
      }
    }
    &:hover {
      .p-dropdown {
        border-color: var(--light-ui-03);
        transition: 150ms;
      }
    }
    .p-dropdown {
      width: 100%;
      .p-dropdown-label {
        color: var(--light-text-01);
      }
      &.p-focus:not(.p-disabled) {
        border-color: var(--light-text-01);
        box-shadow: var(--light-text-01);
      }
    }
    .p-dropdown-panel {
      .p-dropdown-items {
        .p-dropdown-item {
          &.p-highlight.p-focus {
            color: var(--light-text-01);
            background-color: var(--light-ui-05);
          }
          &:not(.p-highlight):not(.p-disabled).p-focus {
            background-color: var(--light-ui-05);
          }
          color: var(--light-text-01);
        }
      }
    }
  }
  p-password {
    &.active {
      div {
        input {
          border-color: var(--light-ui-01);
          transition: 150ms;
        }
      }
    }
    &:focus {
      div {
        input {
          border-color: var(--light-ui-02);
          outline: none;
          transition: 150ms;
        }
      }
    }
    &:hover {
      div {
        input {
          border-color: var(--light-ui-03);
          transition: 150ms;
        }
      }
    }
    div {
      input {
        color: var(--light-text-01);
        width: 100%;
        ::placeholder {
          color: var(--light-text-03);
        }
      }
    }
  }
  small {
    align-items: center;
    display: flex;
    font-size: 12px;
    margin-top: 8px;
    &.p-error {
      &.hidden {
        visibility: hidden;
      }
    }
  }
  .p-password {
    width: 100%;
    input {
      width: 100%;
    }
  }
}
.p-checkbox {
  .p-checkbox-box {
    border-color: var(--light-ui-03);
    &.p-focus {
      border-color: var(--light-ui-03);
    }
    &.p-highlight {
      border-color: var(--light-primary-01);
      background-color: var(--light-background-03);
    }
    .p-checkbox-icon {
      background-color: var(--light-primary-01);
      border-radius: 1px;
    }
  }
}

/****** Radio Button ******/
.radio-button-container {
  .p-radiobutton {
    .p-radiobutton-box {
      border: 1px solid var(--light-ui-03);
      &.p-highlight {
        border-color: var(--light-primary-01);
        background-color: transparent;
        .p-radiobutton-icon {
          background-color: var(--light-primary-01);
          transform: translateZ(0) scale(1.2, 1.2);
        }
      }
    }
  }
  .label {
    color: var(--light-text-01);
    font-family: var(--font-santander-micro-text);
    font-size: 14px;
    line-height: 20px;
    margin-left: 15px;
  }
}

.radio-button {
  .p-radiobutton {
    height: 18px;
    width: 18px;
    .p-radiobutton-box {
      border: 1px solid var(--light-ui-03);
      height: 18px;
      width: 18px;
      &.p-highlight {
        background: var(--light-ui-06);
        border-color: var(--light-primary-02);
        .p-radiobutton-icon {
          background-color: var(--light-primary-02);
        }
      }
      &:not(.p-disabled) {
        &.p-focus {
          box-shadow: none;
        }
      }
    }
  }
}

/****** Rating *****/
// Star icon
.p-rating-icon {
  width: 15px;
  @media (width > 1366px) {
    width: 16px;
  }
}
// Active star icon
.p-rating-icon-active {
  color: var(--light-neutral-02);
}

/****** Spacer *****/
.spacer {
  background-color: var(--light-ui-05);
  height: 1px;
  width: 100%;
}

/****** Tooltip *****/
.p-tooltip {
  max-width: 260px;
  .p-tooltip-text {
    background-color: var(--light-ui-01);
    border-radius: 8px;
    box-shadow: var(--light-shadow-02);
    color: var(--light-text-05);
    font-size: 16px;
    padding: 16px;
  }
  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--light-ui-01);
  }
}
/****** Accordion *****/
p-accordion {
  .accordion-tab {
    .p-accordion-tab {
      .p-toggleable-content {
        &[aria-hidden=true] { // TODO: Delete this when primeng solve problem with accordion
          display: none;
        }
      }
    }
  }
}
[class*=" pi-"] {
  color: var(--light-text-01);
}
.grecaptcha-badge {
  visibility: hidden;
}