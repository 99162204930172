@font-face {
  font-family: 'Santander Headline';
  src: url('assets/fonts/SantanderHeadline-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Headline';
  src: url('assets/fonts/SantanderHeadline-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Micro Text';
  src: url('assets/fonts/SantanderMicroText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Micro Text';
  src: url('assets/fonts/SantanderMicroText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Text';
  src: url('assets/fonts/SantanderText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Text Light';
  src: url('assets/fonts/SantanderText-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Text';
  src: url('assets/fonts/SantanderText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Santander Icons';
  src: url('assets/fonts/fontello.eot?24555534');
  src: url('assets/fonts/fontello.eot?24555534#iefix') format('embedded-opentype'),
  url('assets/fonts/fontello.woff2?24555534') format('woff2'),
  url('assets/fonts/fontello.woff?24555534') format('woff'),
  url('assets/fonts/fontello.ttf?24555534') format('truetype'),
  url('assets/fonts/fontello.svg?24555534#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}